import { Destinataire } from './../../../../../entity/Maileva/FilterMaileva';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-filters-distinataire',
  templateUrl: './filters-distinataire.component.html',
  styleUrls: ['./filters-distinataire.component.css'],
})
export class FiltersDistinataireComponent implements OnInit, OnChanges {
  filterDestinataire: Destinataire = new Destinataire();
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushfilterDestinataire: EventEmitter<Destinataire> = new EventEmitter();
  filtre: any;

  constructor(private chRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.filtre = localStorage.getItem('filtre');
    this.filtre =JSON.parse(this.filtre)
    if (this.filtre) {
    this.filterDestinataire = this.filtre?.destinataire
    this.EmitFilterDestinataire()
  }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetDestinataireForm(changes.Reset.currentValue);
  }

  resetDestinataireForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.filterDestinataire.adresse_destinataire = '';
      this.filterDestinataire.complement_adresse_destinataire = '';
      this.filterDestinataire.cp_destinataire = '';
      this.filterDestinataire.nom_prenom_destinataire = '';
      this.filterDestinataire.ville_destinataire = '';
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushfilterDestinataire.emit(this.filterDestinataire);
    }
  }

  EmitFilterDestinataire() {
    this.onPushfilterDestinataire.emit(this.filterDestinataire);
  }
}
