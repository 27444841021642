import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Inject, Optional} from '@angular/core';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Configuration} from '../configuration';
import {CustomHttpUrlEncodingCodec} from '../encoder';
import {BASE_PATH} from '../variables';
import { ListOrganisme } from 'src/app/entity/Generic/ListOrganisme';
import {UserAdmin} from '../../entity/User/UserAdmin';
import { User } from 'src/app/entity/User/User';
import { Compagnie } from 'src/app/entity/Compagnie';
import { CycleVieBpm } from 'src/app/entity/Bpm/CycleVieBpm';
import { BodyMotif, Motif } from 'src/app/pages/parametres/gestion-status-affaire/fiche-status-affaire/fiche-status-affaire.component';

@Injectable({
  providedIn: 'root'
})
export class ApiAdminBpmService {
  protected basePath = environment.api_url;
  protected PathAdresses = environment.PathAdresses;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public data ;
  public formbuilder: any ;
  public sharedFormbuilder: any ;
  public sharedIsSubmited: boolean = false;
  public sharedFormIsValid: boolean = true;
  selected :number;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Sert a update un groupe des utilisateurs
   *
   * @param id_cycle_vie
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public GetGoJsPreviewData(id_cycle_vie :string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id_cycle_vie === null || id_cycle_vie === undefined) {
      throw new Error('Required parameter id_cycle_vie was null or undefined.');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});


    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/get_gojs/${id_cycle_vie}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }



    /**
   * Sert a update un groupe des utilisateurs Affaires
   *
   * @param id_contrat
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
     public GetGoJsPreviewDataAffaire(id_worlflow :string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

      if (id_worlflow === null || id_worlflow === undefined) {
        throw new Error('Required parameter id_contrat was null or undefined.');
      }

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});


      let headers = this.defaultHeaders;

      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
        headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [];

      return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/get_gojs_affaire/${id_worlflow}`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress,
        }
      );
    }




    /**
   * get list cycle de vie
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListCdv( observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;
    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cdv/list?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  public getListCdvP( observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;
    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cycle_prod/list?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }



      /**
   * get list status  cycle de vie
   * @param idEtat
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListStatus( idEtat:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/etat_opp/list_by_cdv/${idEtat}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  public getListStatusProd( idEtat:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cycle_prod/etat_affaire_by_cycle_prod/${idEtat}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
      /**
   * get list status  cycle de vie
   * @param idCdv
   * @param idService
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListStatusopp( idCdv:string, idService:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/etat_parent_opp/${idCdv}/${idService}`,

      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
        /**
   * get list status affaires
   * @param idContrat
   * @param idService
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
         public getListStatusAffaire( idContrat:string, idService:string,  observe: any = 'body', reportProgress: boolean = false): Observable<any> {



          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          let headers = this.defaultHeaders;

          // authentication (Acess_Token) required
          if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
            headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
          }

          // to determine the Accept header
          let httpHeaderAccepts: string[] = [];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
          }


          const consumes: string[] = [];

          return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/etat_parent_affaire/${idContrat}/${idService}`,
            {
              params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress,
            }
          );
        }

      /**
   * get list etat  affaire
   * @param idContrat
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
       public getEtatAffaireByworkflow( idContrat:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let headers = this.defaultHeaders;

        // authentication (Acess_Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
          headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        const consumes: string[] = [];

        return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cycle_prod/etat_affaire_by_cycle_prod/${idContrat}`,
          {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress,
          }
        );
      }

   /**
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
    public getCategoriebyId( id:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      let headers = this.defaultHeaders;

      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
        headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


      const consumes: string[] = [];

      return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cat_status_opp/${id}`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress,
        }
      );
    }

    /**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
     public createCategorie(categorie: any,observe: any = 'body', reportProgress: boolean = false): Observable<any> {



      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      let headers = this.defaultHeaders;

      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
        headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


      const consumes: string[] = [];

      return this.httpClient.request<any>('post', `${this.basePath}/admin-bpm/cat_status_opp/create?`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress,
          body: categorie
        }
      );
    }

    /**
   * @param categorie
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
     public updateCategorie( categorie:any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
      let headers = this.defaultHeaders;

      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
        headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }


      const consumes: string[] = [];

      return this.httpClient.request<any>('put', `${this.basePath}/admin-bpm/cat_status_opp/update?`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress,
          body: categorie
        }
      );
    }



        public getListCategorie( observe: any = 'body', reportProgress: boolean = false): Observable<any> {



          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          let headers = this.defaultHeaders;

          // authentication (Acess_Token) required
          if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
            headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
          }

          // to determine the Accept header
          let httpHeaderAccepts: string[] = [];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
          }


          const consumes: string[] = [];

          return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cat_status_opp/list`,
            {
              params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress,
            }
          );
        }




        /**
   * get list des documents
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
         public getListTypeDoc( observe: any = 'body', reportProgress: boolean = false): Observable<any> {



          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          let headers = this.defaultHeaders;

          // authentication (Acess_Token) required
          if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
            headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
          }

          // to determine the Accept header
          let httpHeaderAccepts: string[] = [];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
          }


          const consumes: string[] = [];

          return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/type_doc/list?`,
            {
              params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress,
            }
          );
        }
             /**
   * get list des groups
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
              public getListTypeGroupe( observe: any = 'body', reportProgress: boolean = false): Observable<any> {



                let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
                let headers = this.defaultHeaders;

                // authentication (Acess_Token) required
                if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
                  headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
                }

                // to determine the Accept header
                let httpHeaderAccepts: string[] = [];
                const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
                if (httpHeaderAcceptSelected != undefined) {
                  headers = headers.set('Accept', httpHeaderAcceptSelected);
                }


                const consumes: string[] = [];

                return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/group_contrat/list?`,
                  {
                    params: queryParameters,
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                  }
                );
              }
 /**
   * afficher l’identifiant  et le denomination d’une document suivant une partie de sa denomination
   *
   * @param dataQ une partie de sa denomination de documents
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public rechercheTypeDocument(dataQ: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/recherche_type_doc/${dataQ}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }






        /**
   * get list des ligne Produit
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
         public getLigneProd( observe: any = 'body', reportProgress: boolean = false): Observable<any> {



          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          let headers = this.defaultHeaders;

          // authentication (Acess_Token) required
          if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
            headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
          }

          // to determine the Accept header
          let httpHeaderAccepts: string[] = [];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
          }


          const consumes: string[] = [];

          return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/contrat/list?`,
            {
              params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress,
            }
          );
        }




              /**
   * get  ligne de produits by  id
   * @param idLigneP
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getLigneProdById(idLigneP:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/contrat/${idLigneP}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }


  /**
   * creation cycle de vie
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCycleVie(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/admin-bpm/cdv/create?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Data
      }
    );
  }
  public createCycleVieProduction(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/admin-bpm/cycle_prod/create?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Data
      }
    );
  }


     /**
   * creation d'une categorie status opp
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createCatStatutOpp(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/admin-bpm/cat_status_opp/create?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Data
      }
    );
  }

    /**
   * creation  status affaire
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createServiceAffaire(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('post', `${this.basePath}/admin-bpm/cat_status_affaire/create?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Data
      }
    );
  }



    /**
   * creation d'etat opportunite
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
     public createEtatOpp(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

      if (Data === null || Data === undefined) {
        throw new Error('Required parameter Data was null or undefined');
      }

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

      let headers = this.defaultHeaders;

      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
        headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [];

      return this.httpClient.request<any>('post', `${this.basePath}/admin-bpm/etat_opp/create?`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress,
          body: Data
        }
      );
    }



    /**
   * creation d'etat affaue
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
     public createEtatAffaire(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

      if (Data === null || Data === undefined) {
        throw new Error('Required parameter Data was null or undefined');
      }

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

      let headers = this.defaultHeaders;

      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
        headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [];

      return this.httpClient.request<any>('post', `${this.basePath}/admin-bpm/etat_affaire/create?`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress,
          body: Data
        }
      );
    }


    /**
   * creation type de document
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
     public createTypeDoc(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

      if (Data === null || Data === undefined) {
        throw new Error('Required parameter Data was null or undefined');
      }

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

      let headers = this.defaultHeaders;

      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
        headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [];

      return this.httpClient.request<any>('post', `${this.basePath}/admin-bpm/type_doc/create?`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress,
          body: Data
        }
      );
    }

   /**
   * creation ligne produit
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
    public createLigneProduit(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

      if (Data === null || Data === undefined) {
        throw new Error('Required parameter Data was null or undefined');
      }

      let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

      let headers = this.defaultHeaders;

      // authentication (Acess_Token) required
      if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
        headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
      }

      // to determine the Accept header
      let httpHeaderAccepts: string[] = [];
      const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
      if (httpHeaderAcceptSelected != undefined) {
        headers = headers.set('Accept', httpHeaderAcceptSelected);
      }

      // to determine the Content-Type header
      const consumes: string[] = [];

      return this.httpClient.request<any>('post', `${this.basePath}/admin-bpm/contrat/create?`,
        {
          params: queryParameters,
          withCredentials: this.configuration.withCredentials,
          headers: headers,
          observe: observe,
          reportProgress: reportProgress,
          body: Data
        }
      );
    }
 /**
   * update ligne produit
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateLigneProduit(id:string , Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('put', `${this.basePath}/admin-bpm/contrat/update/${id}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Data
      }
    );
  }


      /**
   * get  cycle de vie by  id
   * @param idEtat
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCdvById(idEtat:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cdv/${idEtat}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }


  public getCdvPById(idEtat:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cycle_prod/get/${idEtat}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }



        /**
   * get  type de documents  by  id
   * @param idDoc
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
         public getTypeDocById(idDoc:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          let headers = this.defaultHeaders;

          // authentication (Acess_Token) required
          if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
            headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
          }

          // to determine the Accept header
          let httpHeaderAccepts: string[] = [];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
          }


          const consumes: string[] = [];

          return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/type_doc/${idDoc}`,
            {
              params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress,
            }
          );
        }



        /**
   * get etat by  id
   * @param idEtatopp
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
 public getEtatOppById(idEtat:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          let headers = this.defaultHeaders;

          // authentication (Acess_Token) required
          if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
            headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
          }

          // to determine the Accept header
          let httpHeaderAccepts: string[] = [];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
          }


          const consumes: string[] = [];

          return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/etat_opp/${idEtat}`,
            {
              params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress,
            }
          );
        }









        /**
   * get  categorie de status by  id
   * @param idCategorie
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getCategorieStatutById(idCategorie:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cat_status_opp/${idCategorie}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }



        /**
   * get  Service affaire by  id
   * @param idCategorie
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAffaireServiceById(idCategorie:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cat_status_affaire/${idCategorie}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }




        /**
   * get  status affaire by  id
   * @param id_Status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
         public getAffaireStatutById(id_Status:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


          let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
          let headers = this.defaultHeaders;

          // authentication (Acess_Token) required
          if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
            headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
          }

          // to determine the Accept header
          let httpHeaderAccepts: string[] = [];
          const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
          if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
          }


          const consumes: string[] = [];

          return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/etat_affaire/${id_Status}`,
            {
              params: queryParameters,
              withCredentials: this.configuration.withCredentials,
              headers: headers,
              observe: observe,
              reportProgress: reportProgress,
            }
          );
        }



   /**
   * update cycle de vie
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCycleVie(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('put', `${this.basePath}/admin-bpm/cdv/update?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Data
      }
    );
  }


  public updateCycleVieProduction(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>('put', `${this.basePath}/admin-bpm/cycle_prod/update?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Data
      }
    );
  }


     /**
   * update type de documents
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
      public updatetypeDoc(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (Data === null || Data === undefined) {
          throw new Error('Required parameter Data was null or undefined');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (Acess_Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
          headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        headers = headers.set('Content-Type', 'application/json');

        return this.httpClient.request<any>('put', `${this.basePath}/admin-bpm/type_doc/update?`,
          {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress,
            body: Data
          }
        );
      }


     /**
   * update etat opportunite
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
      public updateEtatOpportunite(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (Data === null || Data === undefined) {
          throw new Error('Required parameter Data was null or undefined');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (Acess_Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
          headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.request<any>('put', `${this.basePath}/admin-bpm/etat_opp/update?`,
          {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress,
            body: Data
          }
        );
      }


           /**
   * update etat affaire
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
            public updateEtatAffaire(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

              if (Data === null || Data === undefined) {
                throw new Error('Required parameter Data was null or undefined');
              }

              let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

              let headers = this.defaultHeaders;

              // authentication (Acess_Token) required
              if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
                headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
              }

              // to determine the Accept header
              let httpHeaderAccepts: string[] = [];
              const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
              if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
              }

              // to determine the Content-Type header
              const consumes: string[] = [];

              return this.httpClient.request<any>('put', `${this.basePath}/admin-bpm/etat_affaire/update?`,
                {
                  params: queryParameters,
                  withCredentials: this.configuration.withCredentials,
                  headers: headers,
                  observe: observe,
                  reportProgress: reportProgress,
                  body: Data
                }
              );
            }
   /**
   * update Categorie statut
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateCategorieStaut(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<any>('put', `${this.basePath}/admin-bpm/cat_status_opp/update?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Data
      }
    );
  }


    /**
   * update  statut Affaire
   *
   * @param Data
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateServiceAffaire(Data: any, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (Data === null || Data === undefined) {
      throw new Error('Required parameter Data was null or undefined');
    }

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];
    return this.httpClient.request<any>('put', `${this.basePath}/admin-bpm/cat_status_affaire/update?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
        body: Data
      }
    );
  }



      /**
   * get list  categorie status
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListCategorieStatue(  observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cat_status_opp/list?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }






      /**
   * get list Service Affaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListServiceAffaire(  observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/cat_status_affaire/list?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }



      /**
   * get list statut Affaire
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
       public getListStatutAffaire(  observe: any = 'body', reportProgress: boolean = false): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let headers = this.defaultHeaders;

        // authentication (Acess_Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
          headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        const consumes: string[] = [];

        return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/etat_affaire/list?`,
          {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress,
          }
        );
      }

      /**
   * get list  status opportunite

   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getListOppStatus(  observe: any = 'body', reportProgress: boolean = false): Observable<any> {



    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    let headers = this.defaultHeaders;

    // authentication (Acess_Token) required
    if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
      headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    const consumes: string[] = [];

    return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/etat_opp/list?`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }






      /**
   * get list classe  status opportunite

   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
       public getListClasse(  observe: any = 'body', reportProgress: boolean = false): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let headers = this.defaultHeaders;

        // authentication (Acess_Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
          headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        const consumes: string[] = [];

        return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/class_etats/list?`,
          {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress,
          }
        );
      }



      public insertMotif(Data: BodyMotif , observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (Data === null || Data === undefined) {
          throw new Error('Required parameter Data was null or undefined');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});

        let headers = this.defaultHeaders;

        // authentication (Acess_Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
          headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [];

        return this.httpClient.request<any>('post', `${this.basePath}/admin-bpm/motif/insert_or_update`,
          {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress,
            body: Data
          }
        );
      }



      public getListMotifStatut(id_Status:string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        let headers = this.defaultHeaders;

        // authentication (Acess_Token) required
        if (this.configuration.apiKeys && this.configuration.apiKeys['idSession']) {
          headers = headers.set('idSession', this.configuration.apiKeys['idSession']);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
          headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        const consumes: string[] = [];

        return this.httpClient.request<any>('get', `${this.basePath}/admin-bpm/motif/list/${id_Status}`,
          {
            params: queryParameters,
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress,
          }
        );
      }

   
}
